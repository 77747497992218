<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ $t('message.grantAppicAccessTo') + ': ' + contactName }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="grantAppicAccessForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.contactEmail') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1 class="font-weight-bold">{{ contactEmail }}</v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.newPassword') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :placeholder="$t('message.minimumLength') + ': 8 characters'"
                                :rules="[rules.characters,rules.empty]"
                                :value="newPassword"
                                autocomplete="new-password"
                                background-color="grey lighten-3"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                type="password"
                                @change="newPassword = $event"
                            ></v-text-field>
                            <v-btn small @click="generatePassword()" class="mt-3 px-2 indigo lighten-2 white--text">{{ $t('message.generatePassword') }}</v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.companies') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <ul>
                                <template v-for="(company, index) in contactCompanies">
                                    <li v-if="company.otsname != null" :key="index">{{ company.otsname }}</li>
                                </template>
                            </ul>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.grant"
                    color="info"
                    small
                    @click="grantAccess()"
                >{{ $t('message.proceed') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import srs from 'secure-random-string'
import {api} from "Api";


export default {
    name: "GrantAppicAccess",
    props: ['customerId','contactEmail','contactName','dialog','dialogClosed','grantDone'],
    data() {
        return {
            contactCompanies: [],
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            loading: {
                get: false,
                grant: false
            },
            minPasswordLength: 8,
            newPassword: null,
            rules: {
                characters: v => (v && /^([a-zA-Z0-9]{8,})$/.test(v)) || this.$t('message.buyerAppicPasswordRule'),
                // characters: v => (v && /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(v)) || this.$t('message.buyerAppicPasswordRule'),
                empty: v => !!v || this.$t('message.required'),
                // length: v => (v || '').length > this.minPasswordLength || this.$t('message.minimumLength') + ': 8 characters'
            },
            validForm: true
        }
    },
    computed: {
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        generatePassword() {
            this.newPassword = srs({length: 8, alphanumeric: true})
        },
        getContactCompanies(contactEmail) {
            return new Promise ((resolve, reject) => {
                api
                    .get('/contacts/' + contactEmail + '/companies')
                    .then(response => {
                        this.contactCompanies = response.data.data
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        grantAccess() {
            if(this.$refs.grantAppicAccessForm.validate()){
                let contact = {
                    email: this.contactEmail,
                    name: this.contactName,
                    password: this.newPassword,
                    customerId: this.customerId
                }
                let promise = new Promise ((resolve, reject) => {
                    api
                        .post("/contacts/appic-access", {
                            contact: contact
                        })
                        .then(response => {
                            if (response.data.status == 'success') {
                                resolve('done')
                            } else {
                                resolve(response.data.message)
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
                this.loading.grant = true
                promise
                    .then(response => {
                        if(response == 'done'){
                            this.$toast.success(this.$t('message.successes.appicAccessGranted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.grant = false
                            this.$emit('access-granted')
                            this.editDialog = false
                        } else {
                            this.$toast.error(response,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.$emit('access-not-granted')
                            this.loading.grant = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.appicAccessNotGranted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.$emit('access-not-granted')
                        this.loading.grant = false
                    })
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                this.loading.get = true
                this.getContactCompanies(this.contactEmail)
                    .then(() => {
                        this.loading.get = false
                        this.edit_dialog = value
                    })
            } else {
                this.contactCompanies = []
                this.newPassword = null
                this.edit_dialog = value
            }
        }
    }
}
</script>

<style scoped>

</style>